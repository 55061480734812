module.exports = {
  theme: {
    colors: {
      primary: '#2962ff',
      black: '#1a1a1a',
      pink: '#e60064',
      pinkLight: '#fbccc9',
      sand: '#fff6d5',
      green: '#00c853',
      success: '#00c853',
      blue: '#2962ff'
    },
    fonts: {
      sans: [
        'Source Sans Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'sans',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        font: 'sans',
        leading: 'sm',
        weight: 'semibold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'normal'
      },
      link: {
        color: 'primary'
      }
    },
    border: {
      radius: '0'
    }
  }
}
