import { make } from 'vuex-pathify'

const state = () => ({
  id: null,
  time: null,
  stale: false,
  address: {},
  data: {},
  token: null
})

const getters = {
  ...make.getters(state),

  totalItems(state) {
    return state.data.quantity || 0
  }
}

const mutations = {
  ...make.mutations(state),

  ADD_ITEM(state, { quantity, expiresAt }) {
    state.time  = new Date(expiresAt)
    state.data  = { ...state.data, quantity }
    state.stale = true
  },

  REMOVE_ITEM(state, { quantity }) {
    state.data  = { ...state.data, quantity }
    state.stale = true
  },

  UPDATE_DATA(state, data) {
    state.data  = data
    state.stale = false
  },

  CLEAR_DATA(state) {
    state.id      = null
    state.time    = null
    state.stale   = false
    state.address = {}
    state.data    = {}
    state.token   = null
  }
}

const actions   = {
  ...make.actions(state),

  addItem({ commit }, item) {
    commit('ADD_ITEM', item)
  },

  removeItem({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  update({ commit }, data) {
    commit('UPDATE_DATA', data)
  },

  clear({ commit }) {
    commit('CLEAR_DATA')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
