export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"cocktailinabox@barsolutions.gr","address":"Λεωφ. Κηφισίας 76, Μαρούσι","geoAddress":"BarSolutions","phone":"+30 694 9120005"},"en":{"icons":true,"email":"cocktailinabox@barsolutions.gr","address":"Leoforos Kifisias 76, Amarousion","geoAddress":"BarSolutions","phone":"+30 694 9120005"}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
