
export default {
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      overflow: 'hidden'
    }
  },
  data() {
    return {
      stuck: false,
      links: [
        { label: 'pages.home', route: 'index' }
      ]
    }
  },
  computed: {
    menuSectionAttrs() {
      return {
        items: 'center',
        width: 'full',
        grow: true
      }
    },
    isHome() {
      return this.$route.path == '/'
    },
    border() {
      return this.isHome ? this.stuck : true
    },
    position() {
      return this.isHome ? 'absolute' : null
    }
  },
  methods: {
    onStick() {
      this.stuck = true
    },
    onUnstick() {
      this.stuck = false
    }
  }
}
