
export default {
  name: 'FooterColumns',
  vessel: {
    social: {
      color: 'pink',
      fontSize: 'md',
      marginTop: 'lg',
      negativeMarginLeft: 'xs'
    }
  },
  data() {
    return {
      quickLinks: [
        {
          path: '/auth/login',
          label: 'footer.login',
          translate: true
        },
        // {
        //   path: 'terms-of-service',
        //   label: 'footer.termsOfService',
        //   translate: true
        // },
        // {
        //   path: 'cancellation-policy',
        //   label: 'footer.cancellationPolicy',
        //   translate: true
        // },
        {
          path: 'cookie-policy',
          label: 'footer.cookiePolicy',
          translate: true
        },
        {
          path: 'contact',
          label: 'footer.contact',
          translate: true
        },
      ],
      socialAccounts: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/Barsolutions.Greece'
        },
        {
          name: 'LinkedIn',
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/bar-solutions-greece'
        }
      ]
    }
  }
}
