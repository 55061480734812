import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=1262c300&"
import script from "./Layout.vue?vue&type=script&lang=js&"
export * from "./Layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/opt/build/repo/components/layout/TopBar.vue').default,FooterColumns: require('/opt/build/repo/components/layout/FooterColumns.vue').default,DeliveryCartDrawer: require('/opt/build/repo/node_modules/@evince/delivery/lib/packages/CartDrawer.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
