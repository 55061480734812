export default function({ app, store }, inject) {
  const options = {"continuePath":"\u002Forder"}

  inject('cart', {
    get continuePath() {
      return app.$pagePath(options.continuePath)
    },
    get canCheckout() {
      return store.getters['shoppingCart/totalItems'] > 0
    },
    continueShopping() {
      app.$navigateTo(options.continuePath)
    }
  })
}
