import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b7c1c2ce = () => interopDefault(import('../node_modules/@evince/delivery/lib/pages/checkout.vue' /* webpackChunkName: "pages/eshop/checkout" */))
const _0ae7bf9c = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/checkout.vue' /* webpackChunkName: "pages/eshop/checkout" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _1626e8d4 = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _330b853b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders.vue' /* webpackChunkName: "pages/eshop/orders" */))
const _516c7b85 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/payment-methods.vue' /* webpackChunkName: "pages/eshop/payment-methods" */))
const _407be469 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shipping-methods.vue' /* webpackChunkName: "pages/eshop/shipping-methods" */))
const _5bd9787f = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shopping-cart.vue' /* webpackChunkName: "pages/eshop/shopping-cart" */))
const _0229a59b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/terms-of-service.vue' /* webpackChunkName: "pages/eshop/terms-of-service" */))
const _d6fc104c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _824a4a7c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _44eda9c6 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders-id.vue' /* webpackChunkName: "pages/eshop/orders-id" */))
const _13972652 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/receipt.vue' /* webpackChunkName: "pages/eshop/receipt" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _b7c1c2ce,
    name: "checkout___el"
  }, {
    path: "/checkout",
    component: _0ae7bf9c,
    name: "checkout___el"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___el"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/order",
    component: _1626e8d4,
    name: "order___el"
  }, {
    path: "/orders",
    component: _330b853b,
    name: "orders___el"
  }, {
    path: "/payment-methods",
    component: _516c7b85,
    name: "payment-methods___el"
  }, {
    path: "/shipping-methods",
    component: _407be469,
    name: "shipping-methods___el"
  }, {
    path: "/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___el"
  }, {
    path: "/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___el"
  }, {
    path: "/auth/login",
    component: _d6fc104c,
    name: "auth-login___el"
  }, {
    path: "/auth/register",
    component: _824a4a7c,
    name: "auth-register___el"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/orders/:id",
    component: _44eda9c6,
    name: "orders-id___el"
  }, {
    path: "/receipt/:id",
    component: _13972652,
    name: "receipt___el"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
