import { reactive } from 'vue'

let deferredPrompt

const pwa = reactive({
  loading: false,
  supported: false,
  installed: true
})

if (typeof window !== 'undefined') {
  if ('BeforeInstallPromptEvent' in window) {
    pwa.supported = true
    pwa.installed = true
  } else {
    pwa.supported = false
    pwa.installed = false
  }

  window.addEventListener('beforeinstallprompt', (event) => {
    deferredPrompt = event
    event.preventDefault()

    pwa.installed = false
    pwa.loading = false

    console.info('PWA install prompt detected')
  })

  window.addEventListener('appinstalled', () => {
    deferredPrompt = null

    pwa.installed = true
    pwa.loading = false

    console.info('PWA installed successfully')
  })
}

export default (_ctx, inject) => {
  pwa.meta = {
    name: 'Cocktail in a Box',
    text: 'The 1st Cocktail online delivery service',
    color: '#2962ff',
    icon: '/icon.png',
    hostname: 'www.cocktailinabox.gr',
    startUrl: '/'
  }

  pwa.install = async function install() {
    if (!deferredPrompt) return

    pwa.loading = true
    deferredPrompt.prompt()

    const { outcome } = await deferredPrompt.userChoice

    if (outcome === 'dismissed') {
      pwa.loading = false
      pwa.installed = false
    }

    deferredPrompt = null
    console.info(`User response to the install prompt: ${outcome}`)

    return outcome
  }

  pwa.open = async function open() {
    window.open(this.meta.startUrl)
  }

  inject('pwa', pwa)
}
