import { render, staticRenderFns } from "./FooterColumns.vue?vue&type=template&id=eaec3256&"
import script from "./FooterColumns.vue?vue&type=script&lang=js&"
export * from "./FooterColumns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copyright: require('/opt/build/repo/components/layout/Copyright.vue').default,ScSocialIcons: require('/opt/build/repo/node_modules/@evince/site/lib/packages/SocialIcons.vue').default,FooterColumn: require('/opt/build/repo/components/layout/FooterColumn.vue').default,ScContactDetails: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Details.vue').default,ScContactButton: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Button.vue').default,ScNewsletterForm: require('/opt/build/repo/node_modules/@evince/site/lib/packages/NewsletterForm.vue').default})
