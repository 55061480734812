
export default {
  vessel: {
    link: {
      $attrs: 'all',
      display: 'block'
    },
    image: {
      height: 'full',
      paddingY: 'xs',
      xl: {
        paddingY: 0
      }
    }
  }
}
